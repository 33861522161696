import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/redux/store";

export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_FAILURE = "PAYMENT_FAILURE";

export interface PaymentStatus {
  value: string;
}

const initialState: PaymentStatus = {
  value: "",
};

export const paymentStateSlice = createSlice({
  name: "paymentState",
  initialState,
  reducers: {
    success: (state) => {
      state.value = PAYMENT_SUCCESS;
    },
    fail: (state) => {
      state.value = PAYMENT_FAILURE;
    },
  },
});

export const { success, fail } = paymentStateSlice.actions;

export const getPaymentStatus = (state: RootState) => state.paymentState;

export default paymentStateSlice.reducer;
