import {createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'

export interface PagePropsState {
  pageProps: {
    [key: string]: any,
  },
  lastPageProps: {
    [key: string]: any,
  },
  nextPageProps: {
    [key: string]: any,
  },
}

const initialState: PagePropsState = {
  pageProps: {},
  lastPageProps: {},
  nextPageProps: {},
}

export const pagePropsSlice = createSlice({
  name: 'pageProps',
  initialState,
  reducers: {
    setNextPageProps: (state, action) => {
      state.nextPageProps = action.payload;
    },
    upgradePageProps: (state) => {
      state.lastPageProps = state.pageProps
      state.pageProps = state.nextPageProps
      state.nextPageProps = {}
    },
    downgradePageProps: (state) => {
      state.nextPageProps = state.pageProps
      state.pageProps = state.lastPageProps
      state.lastPageProps = {}
    },
    // this would only be used if there was no manifest to preload state from
    addPageProps: (state, action) => {
      state.pageProps.pageProps = action.payload;
    },
  },
})

export const { setNextPageProps, upgradePageProps, downgradePageProps, addPageProps } = pagePropsSlice.actions

export const selectPageProps = (state: RootState) => state.pageProps

export default pagePropsSlice.reducer
