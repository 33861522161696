import { StrictMode } from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "@/App";
import { getStore } from "@/redux/store";
import * as serviceWorkerRegistration from "@/serviceWorkerRegistration";

import "@/styles/tailwind.css";
import { startPollingManifest } from "@/sideEffects/polling-manifest";
import ScrollToTop from "@/ui/global/ScrollToTop";
import { VNClientProvider } from "@/providers/VNClientProvider";
import { POST_MESSAGE_TYPES } from "@/types";

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.addEventListener("message", (event) => {
    if (
      event.data &&
      event.data.type === POST_MESSAGE_TYPES.CLEAR_CACHE_AND_RELOAD
    ) {
      window.location.href = window.location.href.replace("/plp", "/");
    }
  });
}

// asynch since we are waiting for the manifest
getStore().then((store) => {
  const basename = process.env.REACT_APP_VERSION
    ? `/storage/app/${process.env.REACT_APP_VERSION}`
    : ``;
  createRoot(document.getElementById("root")!).render(
    <StrictMode>
      <Provider store={store}>
        <BrowserRouter basename={basename && basename}>
          <VNClientProvider>
            <ScrollToTop />
            <App />
          </VNClientProvider>
        </BrowserRouter>
      </Provider>
    </StrictMode>
  );

  // message service worker

  // start polling for updated manifest
  startPollingManifest();
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
