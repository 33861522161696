import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useAppDispatch } from "@/redux/hooks";
import { hide } from "@/redux/slices/qaToolsSlice";

export default function Collecting() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  //make sure QA tool is hidden when we get to this page
  dispatch(hide());

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      navigate("/");
    }, 4000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <section className="flex flex-col justify-center content-center h-[100vh] text-center font-Montserrat  font-semibold px-4 w-[80%] mx-auto">
      <h1 className="text-center text-xl mb-4 lg:text-6xl uppercase">
        Collecting
      </h1>
      <p className="lg:text-4xl">[Product name]</p>
      <img
        src="https://pokemonpoc.smarterspecies.com/storage/assets/cms/pokemon-p9.png"
        alt="test"
        loading="lazy"
        className="h-[50vh] w-auto object-contain"
      />
      <p className="font-normal font-sans lg:text-4xl">
        Please wait, this page will redirect to Start automatically for new
        session.
      </p>
    </section>
  );
}
