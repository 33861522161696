interface BannerProps {
  bgImage: string;
  copy: string;
}

const Banner: React.FC<BannerProps> = ({ bgImage, copy }) => {
  return (
    <div
      className="z-10 sticky top-0 bg-cover bg-top bg-repeat lg:bg-repeat lg:bg-cover lg:bg-top  h-[220px] lg:h-[15vh] flex items-center justify-center"
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <h1 className="text-white 2xl:text-8xl font-bold sr-only">{copy}</h1>
    </div>
  );
};

export default Banner;
