import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/redux/store";

export interface UserStatusState {
  idle: boolean;
}

const initialState: UserStatusState = {
  idle: false,
};

export const userStatusSlice = createSlice({
  name: "userStatus",
  initialState,
  reducers: {
    idle: (state) => {
      state.idle = true;
    },
    active: (state) => {
      state.idle = false;
    },
  },
});

export const { idle, active } = userStatusSlice.actions;

export const getUserStatus = (state: RootState) => state.userStatus;

export default userStatusSlice.reducer;
