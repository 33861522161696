import PageHOC from "@/ui/HOCs/PageHOC";
import Banner from "@/ui/cms-components/Banner";
import ProductCard from "@/ui/components/ProductCard";
import { db } from "@/db";

export async function fetchData() {
  const response = await db.manifests.get(0);
  console.log(response);
  const productsData = response?.pageProps.pageProps["/plp"];
  console.log(productsData);
  return (productsData) ? productsData : [];
}

export function PLPBase({ data }: any) {
  return (
    <main className="pb-40">
      <Banner {...data.topBanner} />
      <div className="grid grid-cols-3 gap-2 px-4 w-full">
        {data.products.map((item: any, index: number) => (
          <ProductCard {...item} key={index} />
        ))}
      </div>
    </main>
  );
}

const PLP = PageHOC(PLPBase, fetchData);
export default PLP;
