import PageHOC from "@/ui/HOCs/PageHOC";
import { getBaseUrl } from "@/util/get-baseurl";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { success, fail } from "@/redux/slices/paymentStatusSlice";

export async function fetchData() {
  const response = await fetch(getBaseUrl() + "/api/cms/payment.json");
  const data = await response.json();
  return data;
}

export function PaymentBase({ data }: any) {
  const dispatch = useDispatch();

  // Mock payment transaction
  const handleSuccessPayment = () => {
    // Mock a successful payment
    dispatch(success());
  };

  // Mock payment transaction
  const handleFailedPayment = () => {
    // Mock a failed payment
    dispatch(fail());
  };

  return (
    <div className="payment-page flex flex-col justify-center content-center h-[100vh]">
      <h1 className="font-Montserrat font-semibold text-center uppercase text-lg py-4">
        Pay with mobile device or card
      </h1>
      <img src={data.mainImage} alt="" className="mx-auto w-auto" />
      <div className="flex justify-center">
        <Link
          to="/checkout"
          className="w-auto justify-center rounded-full bg-pokemon-yellow py-2 px-4 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 mx-2"
          onClick={handleSuccessPayment}
        >
          Success Payment
        </Link>
        <Link
          to="/checkout"
          className="w-auto justify-center rounded-full bg-white px-4 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 mx-2"
          onClick={handleFailedPayment}
        >
          Failed Purchase
        </Link>
      </div>
      <img src={data.backgroungImage} alt="" className="mx-auto" />
    </div>
  );
}

const PaymentSelect = PageHOC(PaymentBase, fetchData);
export default PaymentSelect;
