import MediaHOC from "@/ui/HOCs/MediaHOC";

function ImageComponent(props: IImageComponent) {
  return <img {...props} loading="lazy" alt={props.alt} />;
}

interface IImageComponent {
  src: string;
  alt: string;
}

const Image = MediaHOC(
  ImageComponent,
  (props: IImageComponent) => props.src,
  "image",
  "CMS"
);
export default Image;
