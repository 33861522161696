import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import {
  currentVersionReducer,
  mediaTrackerReducer,
  newVersionReducer,
  pagePropsReducer,
  qaToolsReducer,
  paymentStateReducer,
  userStatusReducer,
  vnClientReducer,
} from "@/redux/slices";

const reducer = {
  mediaTracker: mediaTrackerReducer,
  pageProps: pagePropsReducer,
  version: currentVersionReducer, // not from manifest below here
  qaTools: qaToolsReducer,
  userStatus: userStatusReducer,
  paymentState: paymentStateReducer,
  newVersion: newVersionReducer,
  vnClient: vnClientReducer,
};

// this 'works' to get us the types we need
const _dont_use_storeDef = configureStore({
  reducer,
});

let store: typeof _dont_use_storeDef = null as any;

export async function getStore() {
  if (store) return store;

  store = configureStore({
    reducer,
  });
  return store;
}

export type AppDispatch = typeof _dont_use_storeDef.dispatch;
export type RootState = ReturnType<typeof _dont_use_storeDef.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
