import React, { useEffect, useState } from "react";
import { db, getAppStatuses, ManifestTable } from "@/db";
import { useNavigate } from "react-router-dom";
import { getNewSession } from "@/util/get-new-session";
import { useSelector } from "react-redux";
import { getUserStatus } from "@/redux/slices/userStatusSlice";
import { useVNClient } from "@/hooks/useVNClient";
import { POST_MESSAGE_TYPES } from "@/types";

export default React.memo(function QaTools() {
  const [status, setStatus] = useState("");
  const [connection, setConnection] = useState("");
  const [qaMessage, setQaMessage] = useState("");
  const navigate = useNavigate();
  const userStatus = useSelector(getUserStatus);
  const vnClient = useVNClient();

  getAppStatuses().then((statuses) => {
    setStatus(statuses?.status || "");
    setConnection(statuses?.connection || "");
  });

  const getManifestVersion = async () => {
    const response = await db
      .table("manifests")
      .get(0)
      .then((data: ManifestTable) => data);

    return response.version.value;
  };

  const newSession = async () => {
    const isNewManifest = await getNewSession();
    const currentManifestVersion = await getManifestVersion();

    if (isNewManifest) {
      setQaMessage(`Starting new session with updated manifest version ${currentManifestVersion}.
        <br/>You will be redicted to Attract screen in 4 seconds`);
    } else {
      setQaMessage(`Starting new session with same manifest version ${currentManifestVersion}.
        <br/>You will be redicted to Attract screen in 4 seconds`);
    }

    const timeoutId = setTimeout(() => {
      navigate("/");
      setQaMessage(""); //clear messages
    }, 4000);

    return () => clearTimeout(timeoutId);
  };

  const hardRefreshHandler = async () => {
    navigator?.serviceWorker?.controller?.postMessage({
      type: POST_MESSAGE_TYPES.INITIATE_CACHE_CLEAR_AND_RELOAD,
    });
  };

  useEffect(() => {
    console.log("Planogram was changed to:");
    console.log(vnClient.planogram);
  }, [vnClient.planogram]);

  const actionColorHandler = (
    level: "err" | "info" | "warn" | "resp"
  ): string => {
    switch (level) {
      case "err":
        return "text-red-600";
      case "info":
        return "text-blue-600";
      case "warn":
        return "text-yellow-600";
      case "resp":
        return "text-purple-600";
      default:
        return "text-green-600";
    }
  };

  return (
    <section
      className={`qa-tools p-4 fixed top-0 left-0 w-full z-50 bg-black text-white`}
    >
      <div className="font-bold w-full">QA Tools</div>
      <div className="flex items-center gap-8 mb-2">
        <div className="border-solid border-2 border-white rounded mb-2 py-1 px-2">
          App Status: {vnClient.appState}
        </div>
        <div className="border-solid border-2 border-white rounded mb-2 py-1 px-2">
          App readyState: {vnClient.readyState}
        </div>
        <div className="border-solid border-2 border-white rounded mb-2 py-1 px-2">
          App isOnline: {vnClient.isOnline ? "Online" : "Offline"}
        </div>
      </div>
      <div className="flex items-center gap-8 mb-2">
        <div className="border-solid border-2 border-white rounded mb-2 py-1 px-2">
          App Status: {status}
        </div>
        <div className="border-solid border-2 border-white rounded mb-2 py-1 px-2">
          Connection: {connection}
        </div>
        <div className="border-solid border-2 border-white rounded mb-2 py-1 px-2">
          User Status : {userStatus?.idle === true ? "Idle" : "Active"}
        </div>
        <button
          className="px-2 py-1 bg-pokemon-yellow rounded-md text-black font-semibold uppercase text-sm text-center"
          onClick={() => newSession()}
        >
          New session
        </button>

        <button
          className="px-2 py-1 bg-pokemon-yellow rounded-md text-black font-semibold uppercase text-sm text-center"
          onClick={() => hardRefreshHandler()}
        >
          Hard Reload
        </button>
      </div>
      <div className="flex items-center gap-8 mb-2">
        <button
          className="px-2 py-1 bg-red-600 rounded-md text-black font-semibold uppercase text-sm text-center"
          onClick={() => vnClient.log({ level: "err", message: "This is test error message!" })}
        >
          Send err msg
        </button>

        <button
          className="px-2 py-1 bg-yellow-600 rounded-md text-black font-semibold uppercase text-sm text-center"
          onClick={() => vnClient.log({ level: "warn", message: "This is test warn message!", module: "Custom module name" })}
        >
          Send warn msg
        </button>

        <button
          className="px-2 py-1 bg-blue-600 rounded-md text-black font-semibold uppercase text-sm text-center"
          onClick={() => vnClient.log({ level: "info", message: "This is test info message!" })}
        >
          Send info msg
        </button>
      </div>
      {vnClient.messageHistory.length > 0 && (
        <div className="border-solid border-2 flex flex-col justify-start max-h-[100px] overflow-y-auto gap-2 mb-2 max-w-[600px] py-2">
          {vnClient.messageHistory.map((el) => (
            <div
              className={`${el.level === "resp" ? "ml-auto" : "mr-auto"} rounded bg-blue-400 max-w-[90%] px-1`}
            >
              {"{ "}
              action:{" "}
              <span
                className={actionColorHandler(el.level)}
                dangerouslySetInnerHTML={{ __html: el.action }}
              ></span>
              , level: {el.level}, message: {el.message}
              {" }"}
            </div>
          ))}
        </div>
      )}
      {qaMessage && (
        <div className="mt-1 w-full p-1 px-2 bg-green-200 text-green-900 font-semibold text-xs">
          <span dangerouslySetInnerHTML={{ __html: qaMessage }}></span>
        </div>
      )}
    </section>
  );
});
