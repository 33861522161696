import { Link } from "react-router-dom";
import Image from "@/ui/atoms/Image";

export default function Start({ top, bottom }: IStart) {
  return (
    <div className="fixed h-screen w-screen bottom-0 top-0 z-20">
      <div className=" relative flex">
        <Image
          src={top}
          alt="Background top"
          className=" inset-0 w-full h-auto object-cover"
        />
      </div>
      <Link
        to={"/plp"}
        className="button-start absolute flex flex-col justify-end content-end h-screen w-screen top-0 bottom-0"
      >
        <Image
          className="block w-[80%] h-auto mx-auto end-0 -bottom-[26px] lg:-bottom-24 relative"
          src={bottom}
          alt="Background bottom"
        />

        <div className="py-6 bg-pokemon-orange text-white uppercase font-Montserrat font-bold text-6xl lg:py-16 lg:text-9xl w-full text-center">
          Touch to start
        </div>
      </Link>
    </div>
  );
}

interface IStart {
  top: string;
  bottom: string;
}
