import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  getPaymentStatus,
  PAYMENT_SUCCESS,
  PAYMENT_FAILURE,
} from "@/redux/slices/paymentStatusSlice";
import PaymentSuccess from "@/ui/components/PaymentSucess";
import PaymentFail from "@/ui/components/PaymentFail";

export default function Checkout() {
  const paymentStatus = useSelector(getPaymentStatus);
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      // Redirect to the collecting page after timeout
      if (paymentStatus?.value === PAYMENT_SUCCESS) {
        navigate("/collecting");
      }
    }, 3000);

    // Clear timeout if the component unmounts before the timeout
    return () => clearTimeout(timeoutId);
  }, []); // Run effect only once on mount

  return (
    <div className="flex flex-col justify-center content-center h-[100vh]">
      {paymentStatus?.value === PAYMENT_SUCCESS && <PaymentSuccess />}
      {paymentStatus?.value === PAYMENT_FAILURE && <PaymentFail />}
    </div>
  );
}
