import { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch } from "react-redux";
import { idle, active } from "@/redux/slices/userStatusSlice";
import { useNavigate, useLocation } from "react-router-dom";

interface IdleProps {
  timeout?: number;
  promptBeforeIdle?: number;
}

const IdlePrompt: React.FC<IdleProps> = ({
  // TODO: do not merge it to develop
  timeout = 1000 * 60 * 5,
  promptBeforeIdle = 10000,
}) => {
  const [remaining, setRemaining] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const onIdle = () => {
    dispatch(idle());
    setOpen(false);
    navigate("/");
  };

  const onActive = () => {
    dispatch(active());
    setOpen(false);
  };

  const onPrompt = () => {
    setOpen(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout: timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  const handleLogout = () => {
    setOpen(false);
    navigate("/");
  };

  return (
    <div
      className={`relative z-10`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
      style={{
        display: open && pathname !== "/" ? "flex" : "none",
      }}
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full justify-center p-4 text-center items-center">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all my-8 w-[60vw] py-10 px-8 lg:py-36">
            <div className="bg-white px-4 text-center">
              <h3
                className="text-lg lg:text-6xl font-semibold leading-6 text-gray-900 font-Montserrat uppercase mb-4 lg:mb-10"
                id="modal-title"
              >
                Would you like more time?
              </h3>
              <p className="text-md lg:text-4xl lg:mb-10">
                Ending session in {remaining} seconds
              </p>
              <div className="flex gap-5 mt-4 text-md lg:text-4xl">
                <button
                  className="w-full justify-center rounded-full bg-gray-400 py-2 font-semibold text-white shadow-sm hover:bg-orange-500 px-3 uppercase"
                  onClick={handleLogout}
                >
                  No
                </button>
                <button
                  className="w-full justify-center rounded-full bg-pokemon-yellow py-2 lg:py-8 font-semibold text-white shadow-sm hover:bg-orange-500 px-3 uppercase"
                  onClick={handleStillHere}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdlePrompt;
