import { useAppDispatch } from "@/redux/hooks";
import { addImage, addVideo } from "@/redux/slices/mediaTrackerSlice";
import { isServer } from "@/util/environment-check";

export default function MediaHOC(
  Component: any,
  srcLens: (arg: any) => any,
  assetType: "image" | "video",
  source: "Product" | "CMS"
) {
  return function InnerMediaHOC(props: any) {
    const dispatch = useAppDispatch();
    const { productId } = props;
    if (isServer) {
      const src = srcLens(props);
      const payload = { src, assetType, source, productId };
      // We don't need to add product images to the mediaTracker, because each vending machine will have own stock of products
      if (assetType === "image" && source !== "Product") {
        dispatch(addImage(payload))
      }
      else if (assetType === "video") dispatch(addVideo(payload));
    }

    return <Component {...props} />;
  };
}
