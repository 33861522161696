
/**
 * A higher order function that takes a fetch function and returns a new fetch function that retries on failure
 *
 * @param fetchFn the fetch function to use
 * @param retries how many times to retry before giving up
 * @returns function that retries fetch on failure
 * @example
 *   const fetchWithRetry = fetchRetry(fetch);
 *   fetchWithRetry('/storage/manifest/latest/manifest.json')
 *   .then(console.log)
 *   .catch(console.error);
 */

import { formatDate } from "@/util/date";

/**
 * TODO: Add debounce
 */

// const debounce = (func: any, timeout = 300) => {
//   let timer: any;
//   return (...args: any[]) => {
//     clearTimeout(timer);
//     timer = setTimeout(() => { func.apply(this, args); }, timeout);
//   };
// }

export function fetchRetry(fetchFn: typeof fetch, retries = 3, delay = 30000) {
  return async function fetchWithRetry(input: RequestInfo, init?: RequestInit): Promise<Response> {
    let error;
    for (let i = 0; i < retries; i++) {
      console.log(`Trying fetch | ${formatDate(new Date())}`);
      try {
        return await fetchFn(input, init);
      } catch (e) {
        error = e;
        await new Promise((resolve) => setTimeout(resolve, delay));
      }
    }
    throw error;
  }
}

export const fetchRetryUnlimited = fetchRetry(fetch, Infinity);
