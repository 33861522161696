import Image from "@/ui/atoms/Image";
import Start from "@/ui/cms-components/Start";
import PageHOC from "@/ui/HOCs/PageHOC";
import { getBaseUrl } from "@/util/get-baseurl";
import { useEffect } from "react";
import { getNewSession } from "@/util/get-new-session";

export async function fetchData() {
  const response = await fetch(getBaseUrl() + "/api/cms/attract.json");
  const data = await response.json();
  return data;
}

export function BaseHomepage({ data }: any) {
  useEffect(() => {
    // Make sure new updates are applied if there is new manifest everytime we go back to the Start page.
    (async () => {
      await getNewSession();
    })();
  }, []);

  return (
    <main className="pb-40 ">
      <Start {...data.background} />
      <div className="grid grid-cols-2 gap-4 photobanner py-[20%] mx-auto">
        {data.images.map((item: any, index: number) => (
          <div key={index} className={` p-2 flex justify-center`}>
            <Image src={item.src} alt={item.alt} />
          </div>
        ))}
      </div>
    </main>
  );
}

const Homepage = PageHOC(BaseHomepage, fetchData);
export default Homepage;
