import { useState } from "react";
import { motion } from "framer-motion";
interface QuantityProps {
  className?: string;
  isLabel: boolean;
}

const Quantity: React.FC<QuantityProps> = (props: QuantityProps) => {
  const [quantity, setQuantity] = useState(1);

  const handleIncrement = () => {
    if (quantity === 100) return;
    setQuantity(quantity + 1);
  };
  const handleDecrement = () => {
    if (quantity === 0) return;
    setQuantity(quantity - 1);
  };

  return (
    <div className={props.className}>
      <label
        htmlFor="custom-input-number"
        className={`text-gray-700 font-bold align-middle inline text-md mr-3 ${!props.isLabel && "sr-only"}`}
      >
        Quantity
      </label>
      <div className="flex flex-row h-10 w-1/2 rounded-lg relative bg-transparent mt-1">
        <motion.button
          data-action="decrement"
          className="bg-gray-100 border border-gray-200 text-gray-600 hover:text-gray-700 hover:bg-pokemon-yellow h-full w-[100px]  rounded-full mr-1 cursor-pointer outline-none"
          onClick={handleDecrement}
          whileTap={{ scale: 0.85 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          <span className="m-auto text-2xl font-bold">−</span>
        </motion.button>
        <input
          type="number"
          className="outline-none focus:outline-none text-center w-full border border-gray-200 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 rounded-full"
          name="custom-input-number"
          defaultValue={quantity}
          readOnly
        ></input>
        <motion.button
          data-action="increment"
          className="bg-gray-100 border border-gray-200 rounded-full text-gray-600 hover:text-gray-700 hover:bg-pokemon-yellow h-full w-[100px] cursor-pointer ml-1"
          onClick={handleIncrement}
          whileTap={{ scale: 0.85 }}
          transition={{ type: "spring", stiffness: 400, damping: 17 }}
        >
          <span className="m-auto text-2xl font-bold">+</span>
        </motion.button>
      </div>
    </div>
  );
};

export default Quantity;
