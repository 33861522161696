import { useNavigate } from "react-router-dom";
interface IBuyModal {
  isOpen: boolean;
  onClose: () => void;
}
export default function BuyModal({ isOpen, onClose }: IBuyModal) {
  const navigate = useNavigate();

  if (!isOpen) {
    return null; // Don't render anything if not open
  }

  const submitForm = () => {
    navigate("/payment");
  };

  return (
    <div
      className={`relative z-10`}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <form name="payment" onSubmit={submitForm} action="/payment">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="h-6 w-6 text-green-500"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                      />
                    </svg>
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
                    <h3
                      className="text-lg font-semibold leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Almost there
                    </h3>
                    <div className="mt-2">
                      <p className=" text-gray-500">
                        Please enter your email for receipt
                      </p>
                      <div className="flex items-center border-b border-grey-500 py-2">
                        <input
                          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-0 leading-tight focus:outline-none"
                          required
                          type="email"
                          placeholder="pokemon.trainer@mail.com"
                          aria-label="Full name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  type="submit"
                  className="inline-flex w-auto justify-center rounded-full bg-pokemon-yellow py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 px-3"
                >
                  Continue to payment
                </button>
                <button
                  type="button"
                  className="mt-3 inline-flex w-auto justify-center rounded-full bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0"
                  onClick={onClose}
                >
                  Cancel Purchase
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
