import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/redux/store";

interface VersionState {
	value: number
}

const initialState = { value: 0 } satisfies VersionState as VersionState;

export const currentVersionSlice = createSlice({
	name: 'version',
	initialState,
	reducers: {
		setVersion: (state, action) => {
			state.value = action.payload;
		},
	}
})

export const { setVersion } = currentVersionSlice.actions;

export const getVersion = (state: RootState) => state.version;
export const getNewVersion = (state: RootState) => state.newVersion;

export default currentVersionSlice.reducer
