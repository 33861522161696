import { NavLink } from "react-router-dom";
import { useAppDispatch } from "@/redux/hooks";
import { toggle } from "@/redux/slices/qaToolsSlice";
import { HomeSVG, CartSVG, HelpSVG, AssistSVG } from "@/ui/svg";

export default function Navigation() {
  const dispatch = useAppDispatch();
  const handleToggle = () => {
    dispatch(toggle());
  };

  //== For QA test
  const handleStorageClick = () => {
    navigator.serviceWorker.controller?.postMessage({
      type: "SIMULATE_LARGE_DOWNLOAD",
    });
  };

  return (
    <header className="fixed left-0 bottom-0 bg-black w-full  z-10">
      <nav className="flex justify-center uppercase font-Montserrat text-center mt-0 pb-2 text-sm kiosk:text-4xl text-pokemon-yellow  font-semibold">
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "nav-active px-3 py-0 bg-white "
              : "py-2 px-3 bg-black text-white"
          }
          to={"/plp"}
        >
          <HomeSVG className="mx-auto mb-2 w-[50px] lg:w-[150px]" />
          <span>Home</span>
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive
              ? "nav-active px-3 py-0 bg-white"
              : "py-2 px-3 bg-black text-white"
          }
          to={"/cart"}
        >
          <CartSVG className="mx-auto mb-2 w-[50px] lg:w-[150px]" />
          <span className="">Cart</span>
        </NavLink>
        <button
          className="py-2 px-3 bg-black text-white font-semibold"
          onClick={handleToggle}
        >
          <HelpSVG className="mx-auto mb-2 w-[50px] lg:w-[150px]" />
          <span>Help</span>
        </button>
        <NavLink
          className="py-2 px-3 bg-black text-white font-semibold absolute right-0 mr-4"
          to={"/plp"}
          onClick={handleStorageClick}
        >
          <AssistSVG className="mx-auto mb-2 w-[50px] lg:w-[150px]" />
          <span>Assist</span>
        </NavLink>
      </nav>
    </header>
  );
}
