export { default as LoadingSVG } from "./Loading";
export { default as ErrorSVG } from "./Error";
export { default as ChevronLeft } from "./ChevronLeft";

export const HomeSVG = ({ ...props }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-14 -14 100 100"
        fill="currentColor"
        role="image"
        {...props}
      >
        <title>Home Icon</title>
        <path d="M0 38.02H7.43V75.67H28.28V49.65H47.67V75.67H68.2V38.02H75.79L37.78 0L23.88 13.9V9.09H9.7V26.38L0 38.02Z" />
      </svg>
    </>
  );
};

export const CartSVG = ({ ...props }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        fill="currentColor"
        role="image"
        {...props}
      >
        <title>Cart Icon</title>
        <title>Cart</title>
        <path d="M84.21 24.7L31.86 25.27C31.33 25.27 30.86 24.95 30.68 24.45L28.91 19.61C28.33 18.03 26.83 16.99 25.15 16.99H16C13.78 17 11.99 18.81 12 21.03C12.01 23.06 13.66 24.69 15.69 24.69H21.27C21.83 24.69 22.31 25.06 22.47 25.59L33.99 66.06C34.48 67.77 36.03 68.95 37.8 68.96L78.37 69.3C80.61 69.32 82.43 68.02 82.4 65.78C82.4 64.25 81.2 62.32 78.4 62.37L41.68 62.64C41.12 62.64 40.62 62.27 40.48 61.72C40.28 60.97 40.8 60.22 41.57 60.15L80.37 56.61C82.16 56.45 83.63 55.1 83.94 53.33L88.2 29.4C88.64 26.93 86.73 24.67 84.22 24.7H84.21ZM76.66 50.24L39.02 54.03C38.38 54.09 37.78 53.69 37.61 53.06L32.16 33.17C31.99 32.55 32.46 31.94 33.1 31.94H79C79.72 31.94 80.27 32.58 80.16 33.29L77.85 49.09C77.76 49.7 77.27 50.17 76.66 50.23V50.24Z" />
        <path d="M48.23 76.72C48.23 73.5443 45.6556 70.97 42.48 70.97C39.3043 70.97 36.73 73.5443 36.73 76.72C36.73 79.8956 39.3043 82.47 42.48 82.47C45.6556 82.47 48.23 79.8956 48.23 76.72Z" />
        <path d="M77.3999 76.72C77.3999 73.5443 74.8255 70.97 71.6499 70.97C68.4743 70.97 65.8999 73.5443 65.8999 76.72C65.8999 79.8956 68.4743 82.47 71.6499 82.47C74.8255 82.47 77.3999 79.8956 77.3999 76.72Z" />
      </svg>
    </>
  );
};

export const HelpSVG = ({ ...props }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 100 100"
        stroke="currentColor"
        role="image"
        {...props}
      >
        <title>Help Icon</title>
        <path d="M50.39 11C32.3 11 23 24.08 23 24.08L35.14 31.11C35.14 31.11 39.96 24.08 50.39 24.08C56.85 24.08 60.04 27.86 60.1 31.21C60.2 37.71 47.62 43.37 44.9 50.82C44.06 53.12 44.27 56.69 44.27 56.69H59.75C59.75 56.69 59.57 53.12 60.94 51.31C63.74 47.58 76.3 42.55 76.3 28.18C76.29 20.01 69.95 11 50.39 11Z" />
        <path d="M58.14 73.97H65.12C64.48 67.45 58.98 62.36 52.29 62.36C45.6 62.36 40.11 67.45 39.46 73.97H46.36C46.95 71.26 49.36 69.23 52.25 69.23C55.14 69.23 57.55 71.26 58.14 73.97Z" />
        <path d="M52.2502 81.2801C49.2802 81.2801 46.8202 79.1301 46.3202 76.3101H39.4502C39.9902 82.9301 45.5302 88.1401 52.2902 88.1401C59.0502 88.1401 64.5902 82.9301 65.1302 76.3101H58.1802C57.6802 79.1301 55.2202 81.2801 52.2502 81.2801Z" />
        <path d="M48.1299 75.34C48.1299 77.64 49.9899 79.5001 52.2899 79.5001C54.5899 79.5001 56.4499 77.64 56.4499 75.34C56.4499 73.04 54.5899 71.1801 52.2899 71.1801C49.9899 71.1801 48.1299 73.04 48.1299 75.34Z" />
      </svg>
    </>
  );
};

export const AssistSVG = ({ ...props }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        role="image"
        viewBox="0 0 100 100"
        fill="currentColor"
        {...props}
      >
        <title>Assist Icon</title>
        <path d="M50.7199 76.78C48.5999 78.03 46.1299 78.76 43.4799 78.76C35.5699 78.76 29.1699 72.35 29.1699 64.45C29.1699 62.15 29.7299 59.98 30.6899 58.05L23.9499 51.75C21.0899 56.04 19.5899 61.34 20.0999 67C21.1299 78.44 30.5399 87.58 41.9999 88.32C47.9999 88.71 53.5599 86.85 57.9099 83.5L50.7199 76.78Z" />
        <path d="M78.8098 45.68L78.6698 45.48C78.1898 44.91 77.4698 44.59 76.7298 44.63L60.7298 46.13L70.7998 35.3C71.6798 34.36 71.8898 32.66 71.2698 31.53L71.0798 31.18C70.4598 30.27 69.5098 29.78 68.5898 29.26L45.3498 16.23C44.2398 15.62 42.8698 15.73 41.8798 16.51L30.7798 25.77C29.8098 26.58 28.9698 27.73 28.8298 28.99C28.7198 30.02 28.9198 31.39 29.6698 32.18C30.5798 33.15 32.3198 33.24 33.5898 33.03C34.6198 32.86 35.5798 32.1 36.3698 31.42L42.9298 25.69C43.4698 25.22 44.2398 25.11 44.8898 25.42L50.5398 28.14C50.9698 28.35 51.1198 28.89 50.8398 29.29C49.1498 31.66 43.8798 38.96 41.5998 41C41.3198 41.25 40.9598 41.39 40.5898 41.44C36.0198 42.02 31.8698 43.9 28.5098 46.71L35.0998 52.87C37.4598 51.15 40.3698 50.12 43.5098 50.12C51.4198 50.12 57.8198 56.53 57.8198 64.43C57.8198 67.24 56.9998 69.85 55.5998 72.06L62.6398 78.64C65.4598 74.76 67.1298 69.98 67.1298 64.81C67.1298 61.02 66.2298 57.43 64.6298 54.26L68.8898 53.69C69.3198 53.63 69.7098 53.98 69.6998 54.41L69.2398 72.85L69.3198 72.92C69.3198 74.89 70.8698 76.56 73.3398 76.82C75.5098 77.05 77.8598 75.04 77.8598 73.59L79.6598 48.79C79.7598 47.68 79.4598 46.56 78.8098 45.65V45.68Z" />
        <path d="M71.1699 27.96C75.8533 27.96 79.6499 24.1634 79.6499 19.48C79.6499 14.7966 75.8533 11 71.1699 11C66.4866 11 62.6899 14.7966 62.6899 19.48C62.6899 24.1634 66.4866 27.96 71.1699 27.96Z" />
      </svg>
    </>
  );
};

export const AddCartSVG = ({ ...props }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="w-auto mx-auto"
        viewBox="0 0 126 126"
        fill="currentColor"
        role="image"
        {...props}
      >
        <title>Add to Cart Icon</title>
        <path d="M79.3327 50.9757L31.9686 51.4914C31.489 51.4914 31.0638 51.2019 30.9009 50.7495L29.2995 46.3705C28.7748 44.9409 27.4176 44 25.8976 44H17.6191C15.6105 44.009 13.991 45.6467 14 47.6552C14.0091 49.4919 15.5019 50.9667 17.3386 50.9667H22.3872C22.8938 50.9667 23.3281 51.3014 23.4729 51.7809L33.8957 88.3966C34.339 89.9437 35.7414 91.0113 37.3428 91.0204L74.0489 91.328C76.0756 91.3461 77.7223 90.1699 77.6951 88.1432C77.6951 86.7589 76.6094 85.0128 74.0761 85.058L40.8533 85.3023C40.3466 85.3023 39.8943 84.9675 39.7676 84.4699C39.5866 83.7913 40.0571 83.1128 40.7538 83.0494L75.8585 79.8466C77.478 79.7018 78.808 78.4804 79.0884 76.879L82.9427 55.2281C83.3408 52.9933 81.6127 50.9486 79.3418 50.9757H79.3327ZM72.5018 74.0833L38.4466 77.5123C37.8676 77.5666 37.3247 77.2047 37.1709 76.6347L32.24 58.639C32.0862 58.0781 32.5114 57.5262 33.0905 57.5262H74.6189C75.2704 57.5262 75.768 58.1052 75.6685 58.7476L73.5785 73.0428C73.497 73.5947 73.0537 74.0199 72.5018 74.0742V74.0833Z" />
        <path d="M46.7792 98.0412C46.7792 95.168 44.4501 92.8389 41.5769 92.8389C38.7037 92.8389 36.3745 95.168 36.3745 98.0412C36.3745 100.914 38.7037 103.244 41.5769 103.244C44.4501 103.244 46.7792 100.914 46.7792 98.0412Z" />
        <path d="M73.1713 98.0412C73.1713 95.168 70.8422 92.8389 67.969 92.8389C65.0958 92.8389 62.7666 95.168 62.7666 98.0412C62.7666 100.914 65.0958 103.244 67.969 103.244C70.8422 103.244 73.1713 100.914 73.1713 98.0412Z" />
        <path d="M92.9788 26.8951H98.6709C98.9548 26.8775 99.234 26.9728 99.448 27.16C99.662 27.3473 99.7934 27.6115 99.8137 27.8951V52.1451C99.7934 52.4288 99.662 52.693 99.448 52.8802C99.234 53.0675 98.9548 53.1627 98.6709 53.1451H92.9788C92.6949 53.1627 92.4157 53.0675 92.2017 52.8802C91.9876 52.693 91.8562 52.4288 91.8359 52.1451V27.8951C91.8562 27.6115 91.9876 27.3473 92.2017 27.16C92.4157 26.9728 92.6949 26.8775 92.9788 26.8951Z" />
        <path d="M108.93 37.1731V42.864C108.948 43.1479 108.852 43.4273 108.665 43.6414C108.478 43.8554 108.214 43.9868 107.93 44.0071H83.6799C83.3963 43.9868 83.1318 43.8554 82.9446 43.6414C82.7573 43.4273 82.6622 43.1479 82.6798 42.864V37.1731C82.6622 36.8892 82.7573 36.6098 82.9446 36.3958C83.1318 36.1817 83.3963 36.0503 83.6799 36.03H107.93C108.214 36.0503 108.478 36.1817 108.665 36.3958C108.852 36.6098 108.948 36.8892 108.93 37.1731Z" />
      </svg>
    </>
  );
};

export const TrashSVG = ({ ...props }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        role="image"
        {...props}
      >
        <title>Trash Icon</title>
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
        />
      </svg>
    </>
  );
};
