import { getStore } from "@/redux/store";
import { db, getAppStatuses } from "@/db";
import { APP_STATUSES, POST_MESSAGE_TYPES } from "@/types";
import { setVersion } from "@/redux/slices/currentVersionSlice";
import { setNextPageProps } from "@/redux/slices/pagePropsSlice";

/**
 * The function `getNewSession` checks the status of the app and initiates a new user session if the
 * status is ready for a new session.
 * @returns The function `getNewSession` is returning a boolean value indicating whether a new manifest
 * is being used (`true` if a new manifest is being being used, `false` otherwise).
 */
export const getNewSession = async () => {
  let isNewManifest = false;
  const statuses = await getAppStatuses();

  if (statuses && statuses.status === APP_STATUSES.READY_NEW_SESSION) {
    navigator.serviceWorker.controller?.postMessage({
      type: POST_MESSAGE_TYPES.START_NEW_USERS_SESSION,
    });
    const store = await getStore();
    const pageProps = await db.manifests.get(0).then((data) => data?.pageProps);
    store.dispatch(setVersion(store.getState().newVersion.value));
    store.dispatch(setNextPageProps(pageProps));
    isNewManifest = true;

    console.log(
      "\x1b[45m%s\x1b[0m",
      `==> Started new session with new manifest, updates have been applied`
    );
  } else {
    isNewManifest = false;

    console.log(
      "\x1b[43m%s\x1b[0m",
      `==> Started new session same manifest version, nothing to update`
    );
  }

  return isNewManifest;
};
