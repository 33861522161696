import { isDevelopment } from "./environment-check";
import { fetchRetryUnlimited } from "./fetch-retry";

export async function getManifest() {
  const manifestUrl = isDevelopment()
    ? "/storage/manifest/latest/manifest.json"
    : "https://pokemonpoc.smarterspecies.com/storage/manifest/latest/manifest.json";

  const response = await fetchRetryUnlimited(manifestUrl);
  return (await response.json()) as {
    mediaTracker: any;
    pageProps: any;
    version: { value: number };
  };
}
