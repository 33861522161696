import { useState, useEffect } from "react";
import { selectPageProps } from "@/redux/slices/pagePropsSlice";
import { useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";

export default function PageHOC(Component: any, dataFetcher: any) {
  return function InnerPageHOC() {
    const { pageProps } = useSelector(selectPageProps);
    const { pathname } = useLocation();
    const { param } = useParams();
    const props = pageProps[pathname];
    const [data, setData] = useState(props);

    useEffect(() => {
      if (!data) {
        // TODO: log error. this is an error condition since we should have data
        (async () => {
          const data = await dataFetcher(param, { cache: "force-cache" });
          setData(data);
        })();
      }
    }, [data, param]);

    if (!data) return <h1>Loading...</h1>;

    return <Component data={data} />;
  };
}
