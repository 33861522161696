import Dexie from "dexie";
import { APP_CONNECTION, APP_STATUSES } from "@/types";

export const db = new Dexie("PokeDexie") as Dexie & {
  manifests: Dexie.Table<ManifestTable, number>;
  cachedAssets: Dexie.Table<CachedAssetTable, number>;
  system: Dexie.Table<SystemTable, number>;
};

db.version(1).stores({
  manifests: ",version, using",
  cachedAssets: ",cached, url, source",
  system: ",status, connection",
});

db.open()
  .then((db) => db.table("system").get(0))
  .then((data) => {
    if (!data) {
      db.table("system").put(
        {
          status: APP_STATUSES.LOADING,
          connection: APP_CONNECTION.ONLINE,
        },
        0
      );
    }
  });

export const getAppStatuses = async (): Promise<SystemTable | undefined> => {
  try {
    return db.system.get(0);
  } catch (_) {
    console.error("Can't get app statuses!");
  }
};

export const updateAppStatus = async (
  status?: APP_STATUSES,
  connection?: APP_CONNECTION
) => {
  const appStatuses = await db.system.get(0);
  db.system.update(0, {
    status: status ?? appStatuses?.status,
    connection: connection ?? appStatuses?.connection,
  });
};

export interface ManifestTable {
  version: { value: number };
  mediaTracker: {
    [key: string]: {
      assetType: string;
      source: string;
      src: string;
      lastModified: string;
    }[];
  };
  pageProps: {
    [key: string]: any;
  };
}

export interface CachedAssetTable {
  cached: number;
  url: string;
  source: string;
}

export interface SystemTable {
  status: APP_STATUSES;
  connection: APP_CONNECTION;
}
