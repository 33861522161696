import { VNClientContext, VNClientContextType } from "@/context/VNClientContext";
import React, { useContext } from "react";

export const useVNClient = (): VNClientContextType => {
  const context = useContext(VNClientContext);
  if (!context) {
    console.error('useVNClient hook used outside of VNClientProvider');
    throw new Error("useVNClient must be used within a VNClientProvider");
  }
  // console.log('useVNClient hook called, returning context:', context);
  return context;
}
