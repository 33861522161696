import { createSlice } from "@reduxjs/toolkit";

export interface VNClientState {
  appStatus: AppState;
  planogram: PlanogramItem[];
}

const initialState: VNClientState = {
  appStatus: "DispenseSessionNotStarted",
  planogram: [],
};

export const vnClientSlice = createSlice({
  name: "vnClient",
  initialState,
  reducers: {
    setAppStatus: (state, action) => {
      const { status } = action.payload;
      state.appStatus = status;
    },
    setPlanogram: (state, action) => {
      const { planogram } = action.payload;
      state.planogram = planogram;
    },
  },
});

export const { setAppStatus, setPlanogram,  } = vnClientSlice.actions;
export default vnClientSlice.reducer;
