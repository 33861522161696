import { Route, Routes } from "react-router-dom";
import {
  Cart,
  Checkout,
  Homepage,
  Layout,
  NotFound,
  PDP,
  PLP,
  PaymentSelect,
  Collecting,
} from "@/pages";
import { useEffect, useState } from "react";
import { db, updateAppStatus } from "@/db";
import { APP_CONNECTION, APP_STATUSES, POST_MESSAGE_TYPES } from "@/types";
import { useOnlineStatus } from "@/hooks/useOnlineStatus";
import Loading from "@/ui/atoms/Loading";
import { isDevelopment, isServer } from "@/util/environment-check";

function App() {
  const [isLoading, setIsLoading] = useState(!isServer);
  const isAppOnline = useOnlineStatus();

  useEffect(() => {
    if (isAppOnline) {
      navigator?.serviceWorker?.controller?.postMessage({
        type: POST_MESSAGE_TYPES.APP_IS_ONLINE,
      });
      updateAppStatus(undefined, APP_CONNECTION.ONLINE);
    } else {
      navigator?.serviceWorker?.controller?.postMessage({
        type: POST_MESSAGE_TYPES.APP_IS_OFFLINE,
      });
      updateAppStatus(undefined, APP_CONNECTION.OFFLINE);
    }
  }, [isAppOnline]);

  useEffect(() => {
    const getAppStatuses = async () => await db.system.get(0);
    /**
     * This is needed for not showing not ready app on the first load!
     * at first page init, we are initializing React app and installing SW
     * after SW installation run a source download to the cache and when it's done, app is ready to use
     */
    const t = setInterval(() => {
      getAppStatuses().then((data) => {
        if (
          data?.status !== APP_STATUSES.INITIALIZING &&
          data?.status !== APP_STATUSES.LOADING
        ) {
          setIsLoading(false);
          clearInterval(t);
        }
      });
    }, 1000);

    // Need to remove loading because we didn't get update status from SW in dev mode
    if (isDevelopment()) setIsLoading(false);

    return () => {
      clearInterval(t);
    };
  }, []);

  return (
    <>
      {!isLoading && (
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Homepage />} />
            <Route path="/plp" element={<PLP />} />
            <Route path="/products/:param" element={<PDP />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/collecting" element={<Collecting />} />
            <Route path="/payment" element={<PaymentSelect />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      )}
      {isLoading && <Loading />}
    </>
  );
}

export default App;
