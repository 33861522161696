import React, { useState, useEffect, ReactNode } from "react";
import {
  LogHandlerType, VNClientContext, VNClientContextType,
} from "@/context/VNClientContext";

type VNClientProviderProps = {
  children: ReactNode;
};

export type MessageHistoryItem = {
  action: string;
  level: "err" | "info" | "warn" | "resp";
  message: string;
  module?: string;
};

const RECONNECT_INTERVAL = 5000; // 5 seconds

export const VNClientProvider = ({ children }: VNClientProviderProps) => {
  const [client, setClient] = useState<VNClient | null>(null);
  const [isConnected, setIsConnected] = useState(false);
  const [appState, setAppState] = useState("DispenseSessionNotStarted");
  const [readyState, setReadyState] = useState("NotReady");
  const [planogram, setPlanogram] = useState<PlanogramItem[]>([]);
  const [isOnline, setIsOnline] = useState(false);
  const [isDeviceDisabled, setIsDeviceDisabled] = useState(false);
  const [isDeviceOutOfService, setIsDeviceOutOfService] = useState(false);
  const [messageHistory, setMessageHistory] = useState<MessageHistoryItem[]>(
    []
  );

  const filterAndSetPlanogram = (planogram: PlanogramItem[] | undefined): PlanogramItem[] => {
    if (!planogram) return [];
    return planogram.filter(el => el.productName !== 'Unknown Item');
  }

  useEffect(() => {
    const updateProperty = (property: string) => {
      if (!client) return;

      switch (property) {
        case 'readyState':
          setReadyState(client.readyState);
          break;
        case 'planogram':
          setPlanogram(filterAndSetPlanogram(client.planogram) || []);
          break;
        case 'isOnline':
          setIsOnline(client.isOnline);
          break;
        case 'isDeviceDisabled':
          setIsDeviceDisabled(client.isDeviceDisabled);
          break;
        case 'isDeviceOutOfService':
          setIsDeviceOutOfService(client.isDeviceOutOfService);
          break;
        default:
          console.warn(`Unhandled property update: ${property}`);
      }
    }

    const createClient = () => {
      console.log('Creating client...');
      try {
        const newClient = new VNClient(
          (property: string, value: any) => {
            console.log(`VNClient callback: ${property}`);

            if (property === 'appState') {
              setAppState(value.appState);
            } else {
              updateProperty(property);
            }
          },
          () => true // Placeholder for inUserSession
        );

        console.log('Client created successfully');
        setClient(newClient);
        setIsConnected(true);
      } catch (error) {
        console.error('Error creating client:', error);
        setIsConnected(false);
      }
    }

    createClient();

    const reconnectInterval = setInterval(() => {
      if (!isConnected) {
        console.log("Attempting to reconnect...");
        createClient();
      }
    }, RECONNECT_INTERVAL);

    return () => {
      clearInterval(reconnectInterval);
      if (client) {
        // Implement a disconnect method in VNClient if available
        // client.disconnect();
      }
    };
  }, [isConnected]);

  const addMsgToHistory = (data: MessageHistoryItem) => {
    let newMessage = {
      action: data.action,
      level: data.level,
      message: data.message,
    };

    if (data.module) {
      newMessage = Object.assign(newMessage, { module: data.module });
    }

    setMessageHistory((prevState) => [
      ...prevState,
      ...[newMessage],
    ]);
  };

  const log = (data: LogHandlerType) => {
    addMsgToHistory({ action: "&#8657;", level: data.level, message: data.message, module: data.module });
    if (client) {
      client
        .log(data.level, data.message, data.module)
        .then((message) => {
          addMsgToHistory({ action: "&#8659;", level: "resp", message: data.message, module: data.module });
        })
        .catch((error) => {
          addMsgToHistory({
            action: "&#8659;",
            level: "resp",
            message: `Error sending log: ${error}`,
            module: data.module
          });
        });
    } else {
      console.warn("VNClient not initialized, log not sent");
    }
  };

  const value: VNClientContextType = {
    appState,
    readyState,
    planogram,
    isOnline,
    isDeviceDisabled,
    isDeviceOutOfService,
    isConnected,
    messageHistory,
    log,
  };

  return (
    <VNClientContext.Provider value={value}>
      {children}
    </VNClientContext.Provider>
  );
};
