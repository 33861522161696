import { LoadingSVG } from "@/ui/svg";
export default function PaymentSuccess() {
  return (
    <>
      <h1 className="font-Montserrat font-semibold text-center uppercase text-lg py-4">
        Payment is being autorized
      </h1>
      <div className="w-[30%] mx-auto">
        <LoadingSVG className="class-test" />
      </div>
    </>
  );
}
