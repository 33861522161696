import Navigation from "@/ui/global/Navigation";
import QaTools from "@/ui/global/QaTools";
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectQaTools } from "@/redux/slices/qaToolsSlice";
import IdlePrompt from "@/ui/global/IdlePrompt";

export default function Layout() {
  const qaTools = useSelector(selectQaTools);
  const location = useLocation();
  const { pathname } = location;

  const showNavigation = () => {
    if (
      pathname === "/" ||
      pathname === "/payment" ||
      pathname === "/checkout" ||
      pathname === "/collecting"
    ) {
      return false;
    }

    return true;
  };
  return (
    <>
      <IdlePrompt />
      {qaTools?.visible && <QaTools />}
      {showNavigation() && <Navigation />}
      <Outlet />
    </>
  );
}
