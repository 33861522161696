import MediaHOC from "@/ui/HOCs/MediaHOC";

function ImageComponent(props: IImageProductComponent) {
  return <img loading="lazy" {...props} />;
}

interface IImageProductComponent {
  src: string;
  alt: string;
  productId: string;
}

const Image = MediaHOC(
  ImageComponent,
  (props: IImageProductComponent) => props.src,
  "image",
  "Product"
);
export default Image;
