import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { TrashSVG } from "@/ui/svg";
import Quantity from "@/ui/components/Quantity";
export default function Cart() {
  return (
    <section className="pt-10 pb-24 relative">
      <div className="w-full max-w-7xl px-4 md:px-5 lg-6 mx-auto pb-6">
        <h2 className="title font-Montserrat font-bold text-lg leading-10 mb-8 text-center text-black uppercase">
          Shopping Cart
        </h2>
        <div className="border border-gray-100 shadow-md p-4 lg:p-8 grid grid-cols-12 mb-8 gap-y-4 ">
          <div className="col-span-3 img box">
            <img
              src="https://pokemonpoc.smarterspecies.com/storage/assets/pim/404.png"
              alt="speaker"
              className="max-lg:w-full w-[280px] "
            />
          </div>
          <div className="col-span-9 detail w-full pl-3">
            <div className="flex items-center justify-between w-full mb-4">
              <h5 className="font-sans font-bold text-xl leading-2 text-gray-900 pr-3">
                Pokémon Vmax Charizard Rayquaza Umbreon Cards Anime Trading
                Cards Collection
              </h5>
              <button className="text-red-500 border-2 bg-red-50 border-red-100 rounded-full hover:text-white hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-2.5 py-2.5 text-center">
                <TrashSVG className="size-6" />
                <span className="sr-only">Remove item</span>
              </button>
            </div>
            <h6 className=" font-Montserrat font-bold text-xl leading-2 mb-3">
              $220
            </h6>

            <div className="flex justify-between items-center">
              <Quantity
                className={`flex mb-8 custom-number-input h-10 w-full items-center justify-left`}
                isLabel={false}
              />
            </div>
          </div>
        </div>
        <div className="border border-gray-100 shadow-md p-4 lg:p-8 grid grid-cols-12 mb-8 gap-y-4 ">
          <div className="col-span-3 img box">
            <img
              src="https://pokemonpoc.smarterspecies.com/storage/assets/pim/861.png"
              alt="speaker"
              className="max-lg:w-full w-[280px] "
            />
          </div>
          <div className="col-span-9 detail w-full pl-3">
            <div className="flex items-center justify-between w-full mb-4">
              <h5 className="font-sans font-bold text-xl leading-2 text-gray-900 capitalize">
                Pokémon temporal forces booster bundle
              </h5>
              <button className="text-red-500 border-2 bg-red-50 border-red-100 rounded-full hover:text-white hover:bg-red-500 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm px-2.5 py-2.5 text-center">
                <TrashSVG className="size-6" />
                <span className="sr-only">Remove item</span>
              </button>
            </div>
            <h6 className=" font-Montserrat font-bold text-xl leading-2 mb-3">
              $220
            </h6>
            <div className="flex justify-between items-center">
              <Quantity
                className={`flex mb-8 custom-number-input h-10 w-full items-center justify-left`}
                isLabel={false}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-between max-lg:max-w-lg max-lg:mx-auto">
          <h5 className="text-gray-900 font-Montserrat font-semibold text-2xl leading-2 w-full  max-md:mb-4">
            Subtotal
          </h5>

          <div className="flex items-center justify-between gap-5 ">
            <h6 className="font-Montserrat font-bold text-2xl lead-10 ">
              $440
            </h6>
          </div>
        </div>
        <div className="flex flex-row items-center md:items-center justify-between pb-2 border-b border-gray-200 max-lg:max-w-lg max-lg:mx-auto">
          <h5 className="text-gray-900 font-Montserrat font-semibold text-2xl leading-2 w-full  max-md:mb-4">
            Tax
          </h5>

          <div className="flex items-center justify-between gap-5 ">
            <h6 className="font-Montserrat font-bold text-2xl lead-10 ">$25</h6>
          </div>
        </div>
        <div className="flex flex-row items-center md:items-center justify-between max-lg:max-w-lg max-lg:mx-auto pt-2">
          <h5 className="text-gray-900 font-Montserrat font-semibold text-2xl leading-2 w-full  max-md:mb-4">
            Total
          </h5>

          <div className="flex items-center justify-between gap-5 ">
            <h6 className="font-Montserrat font-bold text-3xl lead-10 ">
              $465
            </h6>
          </div>
        </div>
        <div className="max-lg:max-w-lg max-lg:mx-auto">
          <p className="font-normal text-base leading-7 text-gray-500 text-center mb-5 mt-6">
            Lorem ipsum some message
          </p>
          <motion.button
            className="relative inline-flex items-center justify-center uppercase p-1 mb-2 me-2 overflow-hidden font-bold  rounded-full group bg-gradient-to-br from-orange-600 to-pokemon-yellow group-hover:from-pink-500 group-hover:to-pokemon-yellow hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-pokemon-yellow text-xl w-full"
            whileTap={{ scale: 0.9 }}
            transition={{ type: "spring", stiffness: 400, damping: 17 }}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-full group-hover:bg-opacity-0 w-full">
              Checkout
            </span>
          </motion.button>
          <Link
            className="w-full relative inline-flex items-center justify-center uppercase p-1 mb-2 me-2 overflow-hidden font-bold  rounded-full group bg-gradient-to-br from-orange-600 to-pokemon-yellow group-hover:from-pink-500 group-hover:to-pokemon-yellow hover:text-white text-gray-900 focus:ring-4 focus:outline-none  focus:ring-pokemon-yellow text-xl text-center"
            to={"/plp"}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-full group-hover:bg-opacity-0 w-full">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="w-[24px] h-[24px] inline align-middle -mt-[5px] mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                />
              </svg>
              Continue Shopping
            </span>
          </Link>
        </div>
      </div>
    </section>
  );
}
