import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '@/redux/store'

export interface QaToolsState {
  visible: boolean;
}

const initialState: QaToolsState = {
  visible: false,
}

export const qaToolsSlice = createSlice({
  name: 'qaTools',
  initialState,
  reducers: {
    show: (state) => {
      state.visible = true;
    },
    hide: (state) => {
      state.visible = false;
    },
    toggle: (state) => {
      state.visible = !state.visible;
    },
  },
})

export const { show, hide, toggle } = qaToolsSlice.actions

export const selectQaTools = (state: RootState) => state.qaTools

export default qaToolsSlice.reducer
