const dateHelper = dateHelperFactory();
export const formatDate = (date: Date) => {
	const values = `yyyy,mm,dd,hh,mmi,ss,mms`.split(`,`);
	const myDate = dateHelper(date).toArr(...values);
	return `${myDate.slice(0, 3).join(`/`)} ${
		myDate.slice(3, 6).join(`:`)}.${
		myDate.slice(-1)[0]}`;
};

function dateHelperFactory() {
	const padZero = (val: number, len = 2) => `${val}`.padStart(len, `0`);
	const setValues = (date: Date) => {
		let values: any = {
			yyyy: date.getFullYear(),
			m: date.getMonth()+1,
			d: date.getDate(),
			h: date.getHours(),
			mi: date.getMinutes(),
			s: date.getSeconds(),
			ms: date.getMilliseconds(), };
		Object.keys(values).filter(k => k !== `yyyy`).forEach(k =>
			values[k[0]+k] = padZero(values[k], (k === `ms` && 3) || 2) );
		return values;
	};

	// @ts-ignore
	return date => ( {
		values: setValues(date),
		// @ts-ignore
		toArr(...items) { return items.map(i => this.values[i]); },
	} );
}

export const fetchLastModified = (url: string) => {
	return fetch(url, {method: "HEAD"})
		.then((response) => {
			const lastModified = response.headers.get('Last-Modified');
			if (lastModified) return new Date(lastModified);
			return new Date();
		});
}
