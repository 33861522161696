import React from "react";
import { MessageHistoryItem } from "@/providers/VNClientProvider";

export type LogHandlerType = {
	level: "err" | "warn" | "info" | "resp",
	message: string,
	module?: string,
}

export interface VNClientContextType {
	appState: string;
	readyState: string;
	planogram: PlanogramItem[];
	isOnline: boolean;
	isDeviceDisabled: boolean;
	isDeviceOutOfService: boolean;
	isConnected: boolean;
	messageHistory: MessageHistoryItem[],
	log: (data: LogHandlerType) => void,
}

export const VNClientContext = React.createContext<VNClientContextType | null>(null);
