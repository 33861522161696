import { Link } from "react-router-dom";
import ImageProduct from "@/ui/atoms/ImageProduct";
import { motion, Variants } from "framer-motion";
import { AddCartSVG } from "@/ui/svg";

interface ProductCardProps {
  image: string;
  name: string;
  price: string;
  productID: number;
  inventory: number;
}

const cardVariants: Variants = {
  offscreen: {
    y: 150,
    opacity: 0,
  },
  onscreen: {
    y: 50,
    opacity: 1,
    rotate: 0,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const ProductCard: React.FC<ProductCardProps> = ({
  image,
  name,
  price,
  productID,
  inventory,
}) => {
  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.8 }}
      className="flex"
    >
      <motion.div
        variants={cardVariants}
        className={`card-container p-4 lg:p-10 justify-center bg-white shadow-productCard truncate  ${
          inventory < 1 ? "pt-[52px]" : ""
        }`}
      >
        <Link className="block" to={"/products/10"}>
          <div className="relative lg:mb-4">
            {inventory < 1 ? (
              <div className="absolute inset-y-1/2 -left-2 w-full">
                <div className="bg-gray-900 text-white text-center leading-8  uppercase font-normal text-md xl:text-4xl xl:p-2">
                  Sold out
                </div>
              </div>
            ) : (
              <div className="flex items-center justify-end">
                <button className="text-white bg-pokemon-yellow rounded-full hover:text-white hover:bg-pokemon-orange focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium p-1  text-center text-4xl w-[50px] lg:w-[6vw]">
                  <AddCartSVG />
                  <span className="sr-only">Add to cart</span>
                </button>
              </div>
            )}
            <ImageProduct
              className="rounded-t-lg pb-5 -mt-[15px] mx-auto "
              src={image}
              alt={name}
              productid={productID}
            />
          </div>
          <div className="px-5 text-center">
            <h5 className="mb-1 text-lg 2xl:text-4xl font-semibold tracking-tight text-gray-900 capitalize lg:mb-4">
              {name}
            </h5>
            <span className="text-lg 2xl:text-4xl font-bold text-gray-900">
              ${price}
            </span>
          </div>
        </Link>
      </motion.div>
    </motion.div>
  );
};

export default ProductCard;
