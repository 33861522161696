import { useEffect, useState } from "react";

export const useOnlineStatus = () => {
  const [online, setOnline] = useState(
    typeof window !== "undefined" ? window.navigator.onLine : true
  );

  /**
   * TODO: Can be combined with the eventListener online/offline !!!TBD!!!
   * but for our case, we can be connected to the network, but if the network will have no access to the internet
   * we will still be online based on eventListener instead of offline mode, this is why we need to ping some services
   * in addition.
   */
  useEffect(() => {
    const checkInternetConnection = async () => {
      try {
        const response = await fetch("https://pokemonpoc.smarterspecies.com/", {cache: "no-store"});
        setOnline(response.ok);
      } catch (error) {
        setOnline(false);
      }
    };
    // Check internet connection every 5 seconds
    let intervalId = setInterval(checkInternetConnection, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return online;
};
