import { ErrorSVG, ChevronLeft } from "@/ui/svg";
import { Link } from "react-router-dom";

export default function PaymentSuccess() {
  return (
    <>
      <ErrorSVG className="mx-auto size-20 text-red-600" />
      <h1 className="font-Montserrat font-semibold text-center uppercase text-xl text-red-600 py-4">
        Payment Error <br />
        <span className="text-gray-500 text-sm">Let's try that again</span>
      </h1>
      <div className="w-max mx-auto">
        <Link
          to={"/payment"}
          className="relative inline-flex items-center justify-center uppercase p-1 mb-2 me-2 overflow-hidden font-bold  rounded-full group bg-gradient-to-br from-orange-600 to-pokemon-yellow group-hover:from-pink-500 group-hover:to-pokemon-yellow hover:text-white text-gray-900 focus:ring-4 focus:outline-none  focus:ring-pokemon-yellow text-xl text-center"
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-full group-hover:bg-opacity-0 w-full">
            <ChevronLeft className="w-[24px] h-[24px] inline align-middle -mt-[5px] mr-2" />
            Go back
          </span>
        </Link>
      </div>
    </>
  );
}
