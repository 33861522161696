export enum APP_STATUSES {
  LOADING = "Loading",
  INITIALIZING = "Initialising",
  STABLE = "Stable",
  READY_NEW_SESSION = "Ready for New Session",
  UPDATING = "Updating",
  UPDATE_INTERRUPTED = "Update Interrupted",
}

export enum APP_CONNECTION {
  ONLINE = "Online",
  OFFLINE = "Offline",
  UNSTABLE = "Unstable",
}

export enum POST_MESSAGE_TYPES {
  SKIP_WAITING = "SKIP_WAITING",
  NEW_CACHE_DOWNLOAD_COMPLETE = "NEW_CACHE_DOWNLOAD_COMPLETE",
  DOWNLOAD_NEW_CACHE_MANIFEST = "DOWNLOAD_NEW_CACHE_MANIFEST",
  START_NEW_USERS_SESSION = "START_NEW_USERS_SESSION",
  UPDATE_INTERRUPTED = "UPDATE_INTERRUPTED",
  APP_IS_ONLINE = "APP_IS_ONLINE",
  APP_IS_OFFLINE = "APP_IS_OFFLINE",
  INITIATE_CACHE_CLEAR_AND_RELOAD = "INITIATE_CACHE_CLEAR_AND_RELOAD",
  CLEAR_CACHE_AND_RELOAD = "CLEAR_CACHE_AND_RELOAD",
}
