import Image from "@/ui/atoms/Image";
import Banner from "@/ui/cms-components/Banner";
import Quantity from "@/ui/components/Quantity";
import BuyModal from "@/ui/components/BuyModal";
import PageHOC from "@/ui/HOCs/PageHOC";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useState } from "react";
import { getBaseUrl } from "@/util/get-baseurl";
import { ChevronLeft } from "@/ui/svg";

const baseurl = getBaseUrl();

export async function fetchPaths() {
  const response = await fetch(baseurl + "/api/pim/all-products.json");
  const data = await response.json();
  return data.products;
}

export async function fetchData(pid: string) {
  const response = await fetch(baseurl + `/api/pim/${pid}.json`);
  const data = await response.json();
  return data;
}

export function PDPBase({ data }: any) {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <main className="pb-40">
      <Banner bgImage={data.bgImage} copy="" />
      <div className="max-w-6xl mx-auto px-4">
        <div className="flex flex-col">
          <div className="md:flex-1 px-4">
            <div className="h-[400px] rounded-lg mb-4">
              {data.images.map((image: any, i: number) => (
                <Image
                  className="w-auto h-full object-contain mx-auto"
                  key={i}
                  src={image.src}
                  alt={image.alt}
                />
              ))}
            </div>
          </div>
          <div className="md:flex-1 px-12  text-center">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">
              {data.name}
            </h1>
            <p
              className="text-gray-900 text-md mb-4"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
            <div className="flex mb-4 text-md justify-center w-full">
              <div className="mr-4">
                <span className="font-bold text-gray-700">Price:</span>
                <span className="text-gray-600 px-3">$29.99</span>
              </div>
              <div>
                <span className="font-bold text-gray-700">Availability:</span>
                <span className="text-green-600 px-3">In Stock</span>
              </div>
            </div>
            <Quantity
              isLabel={true}
              className={`flex mb-8 custom-number-input h-10 w-full items-center justify-center`}
            />
            <div className="flex flex-col mb-4 ">
              <motion.button
                className="relative inline-flex items-center justify-center uppercase p-1 mb-2 me-2 overflow-hidden font-bold  rounded-full group bg-gradient-to-br from-orange-600 to-pokemon-yellow group-hover:from-pink-500 group-hover:to-pokemon-yellow hover:text-white text-white focus:ring-4 focus:outline-none focus:ring-pokemon-yellow text-xl"
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                onClick={handleOpenModal}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-gray-900 rounded-full group-hover:bg-opacity-0 w-full">
                  Purchase now
                </span>
              </motion.button>
              <Link
                className="relative inline-flex items-center justify-center uppercase p-1 mb-2 me-2 overflow-hidden font-bold  rounded-full group bg-gradient-to-br from-orange-600 to-pokemon-yellow group-hover:from-pink-500 group-hover:to-pokemon-yellow hover:text-white text-gray-900 focus:ring-4 focus:outline-none  focus:ring-pokemon-yellow text-xl text-center"
                to={"/plp"}
              >
                <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white rounded-full group-hover:bg-opacity-0 w-full">
                  <ChevronLeft className="w-[24px] h-[24px] inline align-middle -mt-[5px] mr-2" />
                  Go Back
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {showModal ? (
        <BuyModal isOpen={showModal} onClose={handleCloseModal} />
      ) : null}
    </main>
  );
}

const PDP = PageHOC(PDPBase, fetchData);
export default PDP;
