import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/redux/store";

export interface MediaTrackerState {
  images: {
    src: string;
    assetType: "image" | "video";
    source: "Product" | "CMS";
    lastModified: string;
  }[];
  videos: string[];
}

const initialState: MediaTrackerState = {
  images: [],
  videos: [],
};

export const mediaTrackerSlice = createSlice({
  name: "mediaTracker",
  initialState,
  reducers: {
    addImage: (state, action) => {
      const { src } = action.payload;
      const imageSrcs = state.images.map((image: any) => image.src);
      if (imageSrcs.includes(src)) return;
      state.images = [...state.images, action.payload];
    },
    addVideo: (state, action) => {
      const { src } = action.payload;
      const videoSrcs = state.videos.map((video: any) => video.src);
      if (videoSrcs.includes(src)) return;
      state.videos = [...state.videos, action.payload];
    },
  },
});

export const { addImage, addVideo } = mediaTrackerSlice.actions;

export const selectMediaTracker = (state: RootState) => state.mediaTracker;

export default mediaTrackerSlice.reducer;
