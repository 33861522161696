const planogram = [
  {
    productId: 494,
    totalSelectionInventory: 1,
    price: 1154,
  },
  {
    productId: 755,
    totalSelectionInventory: 3,
    price: 2694,
  },
  {
    productId: 10,
    totalSelectionInventory: 10,
    price: 6164,
  },
  {
    productId: 553,
    totalSelectionInventory: 0,
    price: 164,
  },
  {
    productId: 137,
    totalSelectionInventory: 4,
    price: 16,
  },
  {
    productId: 366,
    totalSelectionInventory: 2,
    price: 29,
  }
];

// export function VNClientTest(this: any, vnClientHost: any = "ws://localhost:2012"): any {
//   //// Properties
//   this.appState = 'DispenseSessionNotStarted'; // Default state is 'DispenseSessionNotStarted'
//   this.isDeviceDisabled = false; // A boolean indicating whether or not the device is disabled
//   this.isDeviceOutOfService = false; // A boolean indicating whether or not the device is out of service
//   this.isDoorOpen = false; // A boolean indicating whether or not the door for servicing the device is open.
//   this.isOnline = true; // A boolean indicating whether VN Client is operating in connected/online mode or not.
//   this.planogram = planogram; // inherit from above
//   this.readyState = true; // A boolean which indicates whether or not VNClient is ready to be used after startup
//
//   ///// Functions
//   this.cancelDispenseSession = null;
//   this.log = null;
//   this.setAccountId = null;
//   this.startDispensing = null;
//   this.startDispenseSession = null;
//
//   ///// manually triggered events
//   this.disconnect = () => {
//     this.isOnline = false;
//   }
//   this.reconnect = () => {
//     this.isOnline = true;
//   }
//   return this;
// }

export default class VNClient {
  appState = "DispenseSessionNotStarted"; // Default state is 'DispenseSessionNotStarted'
  isDeviceDisabled = false; // A boolean indicating whether or not the device is disabled
  isDeviceOutOfService = false; // A boolean indicating whether or not the device is out of service
  isDoorOpen = false; // A boolean indicating whether or not the door for servicing the device is open.
  isOnline = true; // A boolean indicating whether VN Client is operating in connected/online mode or not.
  planogram = planogram; // inherit from above
  readyState = true; // A boolean which indicates whether or not VNClient is ready to be used after startup

  cancelDispenseSession = () => null;

  log = () => {
    console.log("log fn");
  };

  setAccountId = () => {
    console.log("setAccountId fn");
  };

  startDispensing = () => {
    console.log("startDispensing fn");
  };

  startDispenseSession = () => {
    console.log("startDispenseSession fn");
  };

  disconnect = () => {
    console.log("disconnect fn");
    this.isOnline = false;
  };

  reconnect = () => {
    console.log("reconnect fn");
    this.isOnline = true;
  };
}
