import { getManifest } from "@/util/get-manifest"
import { getStore } from "@/redux/store"
import { db, getAppStatuses, ManifestTable, updateAppStatus } from "@/db";
import { setNewVersion } from "@/redux/slices/newVersionSlice";
import { setVersion } from "@/redux/slices/currentVersionSlice";
import { APP_CONNECTION, APP_STATUSES, POST_MESSAGE_TYPES } from "@/types";
import VNClient from "@/util/fake-planogram";
import { isClient } from "@/util/environment-check";

export async function startPollingManifest() {
  pollManifest();
}

/**
 * Delay can be changed and do not need to be simplified for the PoC
 */
let TIMEOUT_BASE = 1000 * 60 * 1;
let timeoutMS = TIMEOUT_BASE;
const client = new VNClient();

async function pollManifest() {
  const statuses = await getAppStatuses();
  if (!statuses) {
    // Decrease timeout for the cases when indexDB is not ready
    timeoutMS = 10000;
    setTimeout(pollManifest, timeoutMS);
    return false;
  }
  if (
    statuses.connection === APP_CONNECTION.OFFLINE ||
    statuses.status === APP_STATUSES.INITIALIZING ||
    statuses.status === APP_STATUSES.UPDATING
  ) {
    timeoutMS = TIMEOUT_BASE;
    setTimeout(pollManifest, timeoutMS);
    return false;
  }

  const store = await getStore();
  let manifest = await getManifest();
  timeoutMS = TIMEOUT_BASE;
  if (store.getState().newVersion.value !== manifest.version.value || store.getState().newVersion.value === 0) {
    // update the store with the new manifest version
    if (store.getState().version.value === 0) {
      store.dispatch(setVersion(manifest.version.value));
    }
    store.dispatch(setNewVersion(manifest.version.value));
    // add copy of manifest if no current manifest in indexDB
    if (isClient) manifest = concatManifestAndPlanogram(manifest, client.planogram);
    if (!await db.manifests.get(1)) await db.manifests.put(manifest as ManifestTable, 1);
    // write the new version to indexedDB
    await db.manifests.put(manifest as ManifestTable, 0);

    if (statuses.status === APP_STATUSES.LOADING) {
      await updateAppStatus(APP_STATUSES.INITIALIZING);
    } else {
      // sent message to the SW to start downloading only manifest updates
      navigator?.serviceWorker?.controller?.postMessage({
        type: POST_MESSAGE_TYPES.DOWNLOAD_NEW_CACHE_MANIFEST,
      })
    }
  }

  setTimeout(pollManifest, timeoutMS)
}

const concatManifestAndPlanogram = (manifest: ManifestTable, planogram: { productId: number, price: number, totalSelectionInventory: number }[]): ManifestTable => {
  manifest.pageProps.pageProps["/plp"].products = manifest.pageProps.pageProps["/plp"].products.map((el: {productID: number}) => {
    const matchingObject = planogram.find(obj => obj.productId === el.productID);
    if (matchingObject) return { ...el, ...matchingObject };
  }).filter((el: { productId: number, price: number, totalSelectionInventory: number }|undefined) => el !== undefined);

  return manifest;
}

